@use 'src/styles/globals.scss' as style;

.address {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: style.$Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #e9ebf2;
  z-index: 20;
  top: 8px;
  right: 20px;
  p {
    margin: 0;
  }
  img {
    margin-left: 8px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
