@use 'src/styles/globals.scss' as style;

.crypto {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    @include style.mainText;
    @include style.smallSize;
    font-weight: 500;
    color: #303239;
  }
  .total_usd {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #757985;
  }
  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #12b76a;
  }
}

.image {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  img {
    margin-right: 12px;
  }
}

.cryptoTotal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: end;
}
