@use 'src/styles/globals.scss' as style;

.invest {
  padding-top: 84px;
  padding-left: 156px;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
}

.left,
.right {
  display: flex;
  flex-direction: column;
}

.right {
  margin-left: 32px;
}

.funds {
  box-sizing: border-box;
  padding: 24px;
  text-align: start;
  width: 592px;
  height: 104px;
  background: #f4f6fb;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  @include style.pointer;
}

.currencies {
  margin-top: 24px;
  box-sizing: border-box;
  width: 592px;
  height: 616px;
  @include style.gradientBlock;
  border-radius: 16px;
  padding: 32px;
  box-sizing: border-box;
}

@media (max-width: 1900px) {
  .invest {
    flex-direction: column;
    align-items: center;
    padding: 84px 0;
    padding-left: 140px;
  }
  .right {
    margin-top: 24px;
    margin-left: 0;
  }
}

@media (max-width: 900px) {
  .invest {
    padding: 120px 0 80px;
  }
}

@media (max-width: 620px) {
  .right, .left {
    align-items: center;
    width: 100%;
  }
  .funds, .currencies {
    width: calc(100% - 40px);
  }
  .currencies {
    padding: 24px;
  }
}

@media (max-width: 450px) {
  .funds {
    padding: 24px 16px;
  }
  .currencies {
    padding: 16px;
  }
}
