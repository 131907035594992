@use 'src/styles/globals.scss' as style;

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonBack {
  box-sizing: border-box;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 43px;

  background: #f4f6fb;
  border-radius: 24px;
  @include style.border(24px);
  @include center;
  @include style.pointer;
}

.btn {
  @include center;
  height: 48px;
  width: 48px;

  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(0, 16, 47, 0.15);
  border-radius: 16px;
}

@media (max-width: 900px) {
  .buttonBack {
    left: 60px;
    top: 20px;
  }
}
