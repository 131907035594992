@use 'src/styles/globals.scss' as style;

.notificationItem {
  @include style.notificationChat;
  min-height: 96px;
  padding: 24px 32px;
  .message {
    margin-top: 12px;
  }
}

@media (max-width: 768px) {
  .notificationItem {
    padding: 16px;
    .message {
      margin-top: 8px;
    }
  }
}
