@use 'src/styles/globals.scss' as style;

.writeBlock {
  box-sizing: border-box;
  width: 351px;
  height: 100%;
  padding: 24px 20px;
}

.response {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: style.$Inter;
  font-style: normal;
  text-align: center;
  color: #303239;

  .status {
    padding: 24px 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .sendMore {
    margin-top: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #A1A6B3;
    &:hover {
      cursor: pointer;
    }
  }
}

.writeHeader {
  font-family: style.$Inter;
  font-style: normal;
  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303239;
    padding-bottom: 24px;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757985;
  }
  p.errorText {
    text-align: start;
    height: 20px;
    color: red;
    @include style.smallerSize;
    margin-bottom: 4px;
    padding-left: 5px;
  }
}

.inputInvest,
textarea.inputInvest {
  padding: 14px 12px;
  isolation: isolate;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border-radius: 12px;
  border: none;
  outline: none;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06));
}

textarea.inputInvest {
  height: 80px;
  resize: none;
}

@media (max-width: 960px) {
  .writeBlock {
    margin-left: 0;
  }
}

@media (max-width: 740px) {
  .writeBlockAnimate {
    animation: none;
  }
  .writeBlockAnimate {
    animation: none;
  }
  .writeBlock {
    height: 100%;
  }
  .response {
    justify-content: center;
    padding-top: 0;
  }
}

@media (max-width: 375px) {
  .writeBlock {
    width: calc(100% + 12px);
  }
}
