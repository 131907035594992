@use 'src/styles/globals.scss' as style;

.fiatCryptoSide {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.container {
  margin-top: 32px;
  width: 100%;
  @include style.borderBlock;
}

.fiatBlock {
  position: relative;
  z-index: 15;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  padding: 24px 32px;
  @include style.mainText;
  @include style.mainSize;
  text-align: start;
  h3 {
    padding-bottom: 16px;
    margin: 0;
    font-weight: 500;
    color: #303239;
  }
}

.mapLink {
  margin-top: -24px;
}

.description {
  @include style.smallerSize;
  color: #a1a6b3;
}

.empty {
  margin-top: 42px;
}
