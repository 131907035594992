@use 'src/styles/globals.scss' as style;

.notification {
  position: absolute;
  width: 476px;
  height: 132px;
  background: #f4f6fb;
  top: 160px;
  right: 0;
  box-shadow: 0px 24px 48px rgba(16, 24, 40, 0.18);
  border-radius: 8px 0px 0px 8px;
  padding: 24px 16px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 25;
  animation: close 0.3s ease-in;
}

.close {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  box-sizing: border-box;
  position: absolute;
  width: 32px;
  height: 32px;
  left: -12px;
  top: -12px;
  background: #232428;
  border-radius: 37px;
  @include style.pointer;
}

.description {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include style.mainText;
  @include style.mainSize;
  h3,
  p {
    text-align: start;
    margin: 0;
  }
  h3 {
    font-weight: 500;
    color: #303239;
  }
  p {
    color: #757985;
  }
}

@keyframes close {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media (max-width: 500px) {
  .notification {
    width: calc(100% - 12px);
  }
}
