@use 'src/styles/globals.scss' as style;

.menuItem {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 24px;
  text-decoration: none;
  svg {
    width: 32px;
    height: 32px;
    transform: rotate(0deg);
  }
  &.active {
    @include style.shadowButton;
    &.active > span {
      color: #fdb022;
    }
    & > img {
      fill: linear-gradient(
        125.04deg,
        rgba(253, 176, 34, 0.23) 0%,
        #fdb022 102.8%
      );
    }
  }
  @include style.pointer;
  span {
    margin-top: 8px;
    @include style.mainText;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #757985;
  }
}

// @media (max-height: 950px) {
//   .menuItem {
//     margin-bottom: 8px;
//   }
// }
