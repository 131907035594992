@use 'src/styles/globals.scss' as style;

.infoChats {
  margin: 16px 0;
  width: 100%;
  @include style.borderBlock;
  padding: 24px;
  box-sizing: border-box;
}

.infoItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include style.mainText;
  @include style.smallSize;

  &:last-child {
    margin-top: 8px;
  }
  p {
    margin: 0;
    color: #595d67;
  }
  span {
    color: #232428;
  }
}
