@use 'src/styles/globals.scss' as style;

.payBillSide {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.description {
  margin-top: 24px;
  text-align: start;
  @include style.mainText;
  @include style.smallSize;
  color: #757985;
}

.buttons {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 56px;
}

.button {
  width: 162px;
}
