@use 'src/styles/globals.scss' as style;

.eventInfo {
  @include style.sideContainer;
  width: 100%;
  margin-top: 32px;
  box-sizing: border-box;
  height: 244px;
  border-radius: 24px;
  @include style.borderBlock;
  padding: 24px 20px;
  @include style.mainText;
  @include style.smallSize;
  text-align: start;
  color: #757985;
  p {
    margin: 0;
  }
  span {
    font-weight: 500;
    color: #232428;
  }
}

.comment {
  margin-top: 24px;
}

.currency {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 40px;
  @include style.mainSize;
}

.total {
  padding-left: 41px;
}

.available {
  margin-top: 8px;
}
