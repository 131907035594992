@use 'src/styles/globals.scss' as style;

.statistic {
  width: 624px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.block {
  height: 100%;
  margin: 0 10px;
}

.creditsList {
  margin-top: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  h2 {
    margin: 0;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #757985;
  }
}

@media (max-width: 680px) {
  .statistic {
    width: 100%;
    flex-direction: column;
  }
  .block {
    height: 100%;
    margin: 10px 0;
  }
}
