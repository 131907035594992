@use 'src/styles/globals.scss' as style;

.rateBlock {
  width: 100%;
  margin-top: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include style.mainText;
  @include style.smallerSize;
  color: #757985;
  margin-bottom: 8px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.summary {
  width: 220px;
  text-align: start;
  @include style.mainText;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #303239;
  span {
    @include style.mainSize;
    font-weight: 600;
    color: #232428;
  }
  .more {
    @include style.mainText;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #303239;
  }
}

.item .rate {
  @include style.mainText;
  @include style.mainSize;
  font-weight: 600;
  color: #232428;
}
