@use 'src/styles/globals.scss' as style;

.container {
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #eceff5;
  z-index: 25;
}

.borderContainer {
  margin: 98px 197px;
  width: 100%;
  height: calc(100% - 196px);
  box-sizing: border-box;
  border-radius: 23px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @include style.borderBlock;
}

.logo {
  position: absolute;
  top: 48px;
  left: 48px;
  width: 90px;
}

.closeBtn {
  position: absolute;
  top: 48px;
  right: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 15;
  span {
    margin-right: 24px;
    @include style.mainText;
    @include style.smallSize;
    color: #303239;
  }
}

.icon {
  // margin-top: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  @include style.border(26px);
  @include style.pointer;
}

.map {
  position: relative;
  margin-bottom: 40px;
  width: calc(100% - 4px);
  height: 70%;
  background: style.$blackDark;
}

.pointMap {
  position: absolute;
  &:nth-child(1) {
    top: 10%;
    left: 45%;
  }
  &:nth-child(2) {
    top: 23%;
    left: 35%;
  }
}

@media (max-width: 1200px) {
  .borderContainer  {
    margin: 98px 80px;
    height: 80vw;
  }
}

@media (max-width: 820px) {
  .borderContainer  {
    margin: 0;
    width: 90%;
    height: 80vw;
  }
  .logo {
    top: 20px;
    left: 20px;
    width: 70px;
  }
  .closeBtn {
    top: 20px;
    right: 20px;
  }
  .map {
    margin-bottom: 20px;
  }
}
