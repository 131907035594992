@use 'src/styles/globals.scss' as style;

.statisticBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 904px;
}

@media (max-width: 1900px) {
  .statisticBlock {
    flex-direction: column;
    align-items: center;
    width: 592px;
    & > div {
      width: 100%;
      height: 100%;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 620px) {
  .invest {
    padding: 120px 0 80px;
  }
  .statisticBlock {
    width: calc(100% - 40px);
  }
}
