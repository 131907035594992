@use 'src/styles/globals.scss' as style;

.activeDealSide {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
  align-items: center;
}

.dealBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
  width: 100%;
  @include style.borderBlock;
  position: relative;
  z-index: 15;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 24px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  .button {
    width: 142px;
  }
}

.chatBlock {
  width: 100%;
  margin-top: 48px;
}
