@use 'src/styles/globals.scss' as style;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: inherit;
  @include style.mainText;
  @include style.smallSize;
  font-weight: 500;
  z-index: 100;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.small {
  height: 36px;
  padding: 8px;
  border-radius: 8px;
}

.main {
  border: none;
  background: linear-gradient(253.47deg, #4a4e57 4.7%, #16181c 99%);
  color: #fafbfd;
}

.secondary {
  color: style.$blackDark;
  border: 1px solid #cacedb;
}
