@use 'src/styles/globals.scss' as style;

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 15;
}

@mixin tabs($w, $h, $color) {
  width: $w;
  height: $h;
  display: flex;
  padding-top: 4px;
  border-bottom: 1px solid #a1a6b3;
  span {
    @include style.mainText;
    @include style.mainSize;
    color: #a1a6b3;
  }
  &:last-child {
    margin-left: 12px;
  }
  &.active {
    border-bottom: 1px solid $color;
    span {
      color: $color;
    }
  }
  @include style.pointer;
}

.tabItem {
  @include tabs(91px, 36px, #ffffff);
}

.tabItemInvest {
  @include tabs(166px, 32px, #232428);
  span {
    @include style.smallSize;
  }
}

.tabItemChanger {
  @include tabs(212px, 32px, #232428);
  span {
    @include style.smallSize;
  }
  @media (max-width: 500px) {
    @include tabs(100%, 32px, #232428);
  }
}

.tabItemChat {
  @include tabs(110px, 32px, #232428);
  span {
    width: 100%;
    text-align: center;
    @include style.smallSize;
  }
}
