.registrationPreview {
  margin-top: 24px;
}

.previewButtons {
  animation: previewAnimation 0.3s ease-in;
}

@keyframes previewAnimation {
  0% {
    transform: translateX(-345px);
  }
  100% {
    transform: translateX(0px);
  }
}
