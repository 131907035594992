@use 'src/styles/globals.scss' as style;

.chatItem {
  @include style.notificationChat;
  height: 80px;
  padding: 16px;
  .message {
    margin-top: 8px;
  }
}
@media (max-width: 768px) {
  .chatItem {
    height: 100%;
  }
}
