@use 'src/styles/globals.scss' as style;

.activateAccount {
  margin-top: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 88px;
  border-radius: 24px;
  border: #dadada 1px solid;
  box-shadow: inset 0px 0px 2px #ffffff;
  padding: 20px;
  & > div {
    margin: 0;
  }
}

.uploadPassport {
  display: none;
  opacity: 0;
}

.icon {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  float: left;
  margin-right: 12px;
  @include style.border(26px);
  @include style.pointer;
}

.uploadLabel {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.fileName {
  position: relative;
  float: right;
  font-size: 14px;
  color: #595d67;
}
