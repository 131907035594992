@use 'src/styles/globals.scss' as style;

.topUpAccount {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.network {
  margin-top: 56px;
  box-sizing: border-box;
  width: 100%;
  height: 208px;
  border-radius: 24px;
  @include style.borderBlock;
  padding: 48px 32px;
  margin-bottom: 24px;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 15;
}
