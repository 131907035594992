@use 'src/styles/globals.scss' as style;

.dealInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include style.mainText;
  text-align: start;
  h3 {
    padding-bottom: 16px;
    margin: 0;
    font-weight: 500;
    color: #303239;
  }
}

.header {
  color: #757985;
  span {
    @include style.smallSize;
    font-weight: 500;
    color: #232428;
  }
}

.top {
  margin-top: 4px;
  margin-bottom: 24px;
}

.total {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    @include style.smallSize;
    color: #595D67;
  }
  &:last-child {
    margin-top: 8px;
  }
}
