@use 'src/styles/globals.scss' as style;

.inputBlock {
  margin: 8px 0;
  width: 100%;
}

.notice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    @include style.mainText;
    @include style.smallSize;
    font-weight: 500;
    color: #484b54;
  }
}
