@use 'src/styles/globals.scss' as style;

.createTranslation {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
  form {
    margin-top: 64px;
    width: 100%;
    .wrap {
      margin-bottom: 8px;
    }
  }
}

.available {
  margin: 0;
  padding-bottom: 56px;
  @include style.mainText;
  @include style.smallSize;
  color: #757985;
  text-align: start;
  span {
    color: #232428;
  }
}

.error {
  display: block;
  color: #ff0000;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 14px;
}
