@use 'src/styles/globals.scss' as style;

.currenciesList {
  @include style.sideContainer;
}

.inputSearch {
  margin-top: 40px;
  margin-top: 24px;
  width: 100%;
}

.list {
  width: 100%;
  max-height: 648px;
  box-sizing: border-box;
  @include style.scrollbar(#FFFFFF);
}

