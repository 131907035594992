@use 'src/styles/globals.scss' as style;

.exchangeFunds {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.container {
  margin-top: 32px;
  width: 100%;
  @include style.borderBlock;
}

.fundsBlock {
  position: relative;
  z-index: 15;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  padding: 24px 32px;
  @include style.mainText;
  @include style.mainSize;
  text-align: start;
}

.buttons {
  margin-top: 128px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  .button {
    width: 162px;
  }
}

@media (max-height: 620px) {
  .buttons {
    margin-top: 24px;
  }
}

