@use 'src/styles/globals.scss' as style;

.createDeposit {
  margin-top: 32px;
  width: 624px;
  height: 100%;
  background: linear-gradient(207.7deg, #4a4e57 8.19%, #16181c 82.79%);
  border-radius: 16px;
  padding: 40px 32px;
  box-sizing: border-box;
}

@media (max-width: 680px) {
  .createDeposit {
    width: 100%;
    height: 100%;
    padding: 24px 16px;
  }
}
