@use 'src/styles/globals.scss' as style;

.investActiveFund {
  width: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.management {
  box-sizing: border-box;
  @include style.borderBlock;
  width: 335px;
  height: 80px;
  border-radius: 16px;
  padding: 11px 16px;
  box-sizing: border-box;
  text-align: start;
  @include style.pointer;
}

.listCurrensy {
  margin-top: 21px;
  width: 100%;
}

@media (max-width: 1300px) {
  .investActiveFund, .management {
    width: 100%;
  }
}
