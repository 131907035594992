@use 'src/styles/globals.scss' as style;

.login {
  @include style.loginContainer;
  z-index: 1;
}

.forgotPassword {
  @include style.mainText;
  @include style.smallerSize;
  font-weight: 500;
  color: style.$blackDark;
  margin-top: 24px;
  margin-bottom: 28px;
}

.buttons {
  @include style.buttonsLogin;
}

.headerAuth {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerAuthPosition {
  position: absolute;
  align-items: flex-start;
  top: 48px;
  left: 48px;
}

.logo {
  width: 126px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoHover:hover {
  cursor: pointer;
}

.logoAnimate {
  position: absolute;
  animation: animateLogo 0.5s ease;
  top: 0;
  left: 0;
  width: 90px;
  height: 40px;
}

@keyframes animateLogo {
  0% {
    top: 68px;
    left: 238px;
  }
  100% {
    top: 0;
    left: 0;
    width: 90px;
    height: 40px;
  }
}

.textAuth {
  width: 131px;
  margin-left: 12px;
  @include style.mainText;
  text-align: start;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  background: linear-gradient(
    90.32deg,
    #cfcfcf 1.01%,
    #a0a0a0 33.4%,
    #dcdcdc 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
