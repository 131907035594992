@use 'src/styles/globals.scss' as style;

.item {
  width: 134px;
  height: 96px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );

  box-shadow: 0px 24px 48px rgba(16, 24, 40, 0.18);
  border-radius: 12px;
  @include style.mainText;
  @include style.pointer;
  p {
    margin: 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #232428;
  }
  span {
    @include style.smallerSize;
    font-weight: 500;
    color: #757985;
  }
  .month {
    color: #303239;
  }
}

.add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  span {
    margin-top: 4px;
    font-weight: 600;
  }
}

@media (max-width: 680px) {
  .item {
    width: 23%;
    padding: 8px;
    p {
      @include style.smallSize;
    }
  }
}
