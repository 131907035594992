.divider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #cacedb;
}

@media (max-width: 780px) {
  .divider {
    display: none;
  }
}
