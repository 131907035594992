@use 'src/styles/globals.scss' as style;

.statisticItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 32px;
  box-sizing: border-box;
  width: 281px;
  height: 180px;
  border-radius: 24px;
  @include style.borderBlock;
  position: relative;
  @include style.mainText;
  img {
    position: absolute;
    top: 32px;
    right: 32px;
  }
  p {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #303239;
  }
  span {
    @include style.smallerSize;
  }
}

.name {
  @include style.mainSize;
  font-weight: 500;
  color: #757985;
  margin-bottom: 8px;
}
