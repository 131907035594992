@use 'src/styles/globals.scss' as style;

.point {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  box-sizing: border-box;
  position: absolute;
  width: 138px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1),
    0px 2px 4px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
}

.dots {
  position: absolute;
  width: 16px;
  height: 16px;
  left: -6px;
  top: -6px;
  background: #ffffff;
  border-radius: 26px;
}

.dotsBg {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 4px;
  top: 4px;

  background: linear-gradient(180deg, #32d583 0%, #3bfc9c 99.99%, #35e38c 100%);
  border-radius: 39px;
}

.title {
  @include style.mainText;
  @include style.smallSize;
  font-weight: 500;
  color: #484b54;
}
