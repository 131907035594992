@use 'src/styles/globals.scss' as style;

.distance {
  @include style.mainText;
  @include style.smallerSize;
  text-align: right;
  color: #484b54;
  display: flex;
  justify-content: center;
  span {
    margin-left: 4px;
  }
}
