@use 'src/styles/globals.scss' as style;

@mixin labelStyle {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #757985;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 14px 12px;
  isolation: isolate;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06));

  background: #ffffff;
  border-radius: 12px;
  &.inputFocus {
    @include style.border(12px);
  }

  textarea {
    width: calc(100% - 28px);
    height: 100%;
    position: absolute;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    border: none;
    @include style.mainText;
    @include style.mainSize;
    color: #232428;
    resize: none;
    padding: 0;
  }
}

.search {
  position: absolute;
  top: 14px;
  left: 12px;
}

.placeholder {
  position: absolute;
  top: 14px;
  left: 12px;
  @include style.mainText;
  @include style.smallSize;
  color: #595d67;
  &.focus {
    transform: translateY(-10px);
    transition: 0.5s ease-in-out;
    @include labelStyle;
  }
  &.empty {
    transform: translateY(0px);
    transition: 0.5s ease-in-out;
    top: 14px;
  }
  &.label {
    top: 4px;
    @include labelStyle;
  }
  &.errorLalel {
    color: #F04438;
  }
}

.errorText {
  height: 16px;
  @include style.mainText;
  font-size: 12px;
  line-height: 16px;
  color: #f04438;
}
