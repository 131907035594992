@use 'src/styles/globals.scss' as style;

.creditsListContainer {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.container {
  margin-top: 32px;
  width: 100%;
  @include style.borderBlock;
}

.creditsListBlock {
  position: relative;
  z-index: 15;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  padding: 24px 32px;
  @include style.mainText;
  @include style.mainSize;
  text-align: start;
  h3 {
    padding-bottom: 16px;
    margin: 0;
    font-weight: 500;
    color: #303239;
  }
}

.creditsList {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.creditItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  background: #F4F6FB;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  padding: 16px 20px;
  @include style.pointer;
  &:not(:first-child) {
    margin-top: 8px;
  }
}

.rate {
  @include style.mainText;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #12B76A;
}

.disabled {
  color: #757985;
}

.disabledBg {
  background: #ECEFF5;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  div {
    width: 211px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include style.mainText;
    @include style.smallSize;
    color: #a1a6b3;
    text-align: start;
    &:last-child {
      margin-top: 4px;
    }
    p {
      margin: 0;
    }
    span {
      text-align: right;
      font-weight: 500;
      color: #484b54;
    }
  }
}
