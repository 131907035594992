@use "src/styles/globals.scss" as style;

.headerAuth {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    margin: 0;
    @include style.mainText;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #303239;
  }
  span {
    text-align: start;
    @include style.mainText;
    font-size: 14px;
    line-height: 20px;
    color: #757985;
  }
}
