@use 'src/styles/globals.scss' as style;

.information {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
}

.informationIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f9f9fa;
  box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.79), -4px -4px 12px #fffefa,
    4px 2px 14px rgba(195, 205, 223, 0.56), inset 2px 4px 4px #ffffff,
    inset -2px -4px 12px -2px #eaedf2;
  border-radius: 64px;
}

.description {
  margin-left: 16px;
  width: 100%;
  text-align: start;
  @include style.mainText;
  @include style.smallSize;
  color: #757985;
}
