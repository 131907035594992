@use 'src/styles/globals.scss' as style;

.line {
  margin: 32px 0;
  margin-left: -32px;
  width: calc(100% + 64px);
  border-top: 1px solid #303239;
}

.button {
  margin-top: 24px;
  width: 100%;
  @include style.pointer;
  height: 56px;

  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  border: none;
  box-shadow: 0px 20px 24px rgba(16, 24, 40, 0.08),
    0px 8px 8px rgba(16, 24, 40, 0.03);
  border-radius: 12px;

  @include style.mainText;
  @include style.smallSize;

  font-weight: 500;
  color: #303239;
}

@media (max-width: 680px) {
  .line {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
}
