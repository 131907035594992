@use 'src/styles/globals.scss' as style;

.infoBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.infoItem {
  width: 88px;
  text-align: center;
  p {
    margin: 0;
    padding-top: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #232428;
  }
  span {
    @include style.mainText;
    @include style.smallerSize;
    font-weight: 500;
    color: #757985;
    &:last-child {
      color: #303239;
    }
  }
}
