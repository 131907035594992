@use 'src/styles/globals.scss' as style;

.settingsAccount {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.widget {
  margin-top: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 96px;
  border-radius: 24px;
  @include style.borderBlock;
  padding: 20px;
  p {
    text-align: start;
    padding-top: 4px;
    margin: 0;
    @include style.mainText;
    @include style.smallerSize;
    color: #595d67;
  }
}

.link {
  position: relative;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h3 {
    margin: 0;
    @include style.mainText;
    @include style.smallSize;
    color: #303239;
  }
  .share {
    margin-left: 4px;
    @include style.pointer;
  }
}

.version {
  width: 100%;
  margin-top: 36px;
  @include style.mainText;
  @include style.smallerSize;
  font-weight: 500;
  color: #a1a6b3;
}
