@use 'src/styles/globals.scss' as style;

.createDepositSide {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.depositBlock {
  margin-top: 32px;
  box-sizing: border-box;
  width: 100%;
  height: 448px;
  border-radius: 24px;
  @include style.borderBlock;
  padding: 24px 32px;
  @include style.mainText;
  @include style.mainSize;
  text-align: start;
  p, h3 {
    margin: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 500;
    color: #757985;
  }
  span {
    color: #303239;
  }
}

.rate {
  margin-top: 20px;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #12b76a;
}

.agreement {
  margin-top: 24px;
  h3 {
    font-weight: 500;
    color: #303239;
  }
  p {
    @include style.smallSize;
    color: #757985;
  }
}

.depositInput {
  margin-top: 40px;
  position: relative;
  z-index: 15;
}

.buttons {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 24px;
}

.button {
  width: 162px;
}
