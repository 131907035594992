@use 'src/styles/globals.scss' as style;

.actionButton {
  height: 64px;
  width: 340px;
  border-radius: 12px;
  box-sizing: border-box;
  @include style.shadowButton;
  padding: 16px 64px 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 15;
  span {
    margin-left: 32px;
    margin-top: 4px;
    @include style.mainText;
    @include style.mainSize;
    color: #303239;
  }
  @include style.pointer;
}

.actionImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1300px) {
  .actionButton {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .actionButton {
    width: 100%;
    padding: 16px 24px 8px;
    span {
      margin-left: 16px;
    }
  }
}
