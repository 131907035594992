@use 'src/styles/globals.scss' as style;

.changePassword {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.form {
  width: 100%;
  margin-top: 104px;
}
