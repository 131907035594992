@use 'src/styles/globals.scss' as style;

.investBlock {
  box-sizing: border-box;
  width: 904px;
  height: 432px;
  padding: 40px 32px;
  @include style.gradientBlock;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 1900px) {
  .investBlock  {
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 3;
    order: 2;
  }
}

@media (max-width: 1300px) {
  .investBlock {
    grid-column: 1 / 2;
    grid-row: 5 / 7;
    order: 0;
    flex-direction: column;
    height: 100%;
  }
}

@media (max-width: 450px) {
  .investBlock {
    padding: 24px 16px;
  }
}
