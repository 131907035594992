.App {
  text-align: center;
  min-height: 100vh;
  background: #eceff5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 604px;
  z-index: -1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
