@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}

.mainContent {
  width: calc(100% - 196px);
  height: 100%;
  color: style.$blackDark;
  margin-left: 196px;
}

.menuContainer {
  display: none;
  position: absolute;
  width: 30px;
  height: 20px;
  top: 20px;
  left: 20px;
  @include style.pointer;
  z-index: 15;
}

.menuBtn,
.menuBtn::before,
.menuBtn::after {
  display: block;
  background-color: #595d67;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menuBtn::before {
  content: '';
  margin-top: 8px;
}

.menuBtn::after {
  content: '';
  margin-top: 16px;
}

@media (max-width: 960px) {
  .mainContent {
    width: calc(100% - 120px);
    margin-left: 120px;
  }
}

@media (max-width: 768px) {
  .mainContent {
    width: 100%;
    margin-left: 0;
  }
  .menuContainer {
    display: block;
  }
}
