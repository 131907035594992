@use 'src/styles/globals.scss' as style;

.chatCurrent {
  position: absolute;
  left: 1000px;
  @include style.sideContainer;
  z-index: 5;
}

.wrap {
  width: 100%;
  margin-top: 16px;
}

@media (max-width: 1580px) {
  .chatCurrent {
    left: auto;
    right: 50px;
  }
}

@media (max-width: 1100px) {
  .chatCurrent {
    left: auto;
    right: auto;
  }
}
