@use 'src/styles/globals.scss' as style;

.bannerBlock {
  @include style.myScoreBlock;
  padding: 0 24px 32px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-end;
}

.bgCard {
  position: absolute;
  width: 232px;
  height: 248px;
  top: 0;
  right: 0;
}

.coins {
  position: absolute;
  width: 228px;
  top: -25px;
  right: -40px;
  z-index: 5;
}

.banner {
  position: relative;
  z-index: 15;
}

.bannerHeader {
  @include style.mainText;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #484b54;
  text-align: start;
  width: 274px;
}

.description {
  margin-top: 8px;
  @include style.mainText;
  @include style.smallSize;
  text-align: start;
  color: #757985;
  width: 274px;
}

.buttons {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 32px;
}

.button {
  width: 188px;
}

@media (max-width: 1900px) {
  .bannerBlock  {
    height: 100%;
    align-self: flex-start;
    grid-column: 2 / 3;
    grid-row: 2;
    order: 1;
  }
}

@media (max-width: 1300px) {
  .bannerBlock {
    width: 100%;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    order: 0;
  }
  .button {
    width: calc(50% - 10px);
  }
}

@media (max-width: 450px) {
  .bannerBlock {
    padding: 16px;
  }
}
