@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

$Ubuntu: 'Ubuntu', sans-serif;
$Inter: 'Inter', sans-serif;
$Roboto: 'Roboto';

$secondaryColor: #595d67;
$blackGray: #484b54;
$blackDark: #232428;

@mixin mainText {
  font-family: $Inter;
  font-style: normal;
  font-weight: 400;
}

@mixin mainSize {
  font-size: 16px;
  line-height: 24px;
}

@mixin smallSize {
  font-size: 14px;
  line-height: 20px;
}

@mixin smallerSize {
  font-size: 12px;
  line-height: 16px;
}

@mixin buttonsLogin {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  .button {
    width: 162px;
  }
}

@mixin checkbox {
  @include mainText;
  font-size: 12px;
  line-height: 16px;
  color: #484b54;
}

@mixin flex($direction, $items, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $items;
  justify-content: $justify;
}

@mixin loginContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 332px;
  z-index: 5;
}

@mixin border($radius) {
  &:before {
    background: linear-gradient(180deg, #cacedb 0%, #ffffff 100%);
    border-radius: $radius;
    content: '';
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    position: absolute;
  }
}

@mixin borderBlock {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 23px;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    padding: 1px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #ffffff 0%, #cacedb 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  @include border(24px);
}

@mixin shadowButton {
  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(0, 16, 47, 0.15);
}

@mixin myScoreBlock {
  width: 436px;
  height: 296px;
  box-sizing: border-box;
  background: #f4f6fb;
  @include borderBlock;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}

@mixin scrollbar($color) {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: $color;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #757985;
    border-radius: 8px;
  }
}

@mixin sideContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 344px;
}

@mixin gradientBlock {
  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

@mixin pointer {
  &:hover {
    cursor: pointer;
  }
}

@mixin notificationChat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
  background: #f4f6fb;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  &:hover {
    cursor: pointer;
  }
  .info {
    text-align: start;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @include mainText;
    @include smallerSize;
    color: #757985;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .active {
    margin-right: 8px;
    width: 8px;
    height: 8px;
    background: #fdb022;
    border-radius: 50%;
  }

  .message {
    @include mainText;
    @include smallSize;
    text-align: start;
    color: $blackGray;
  }
}
