@use 'src/styles/globals.scss' as style;

.kycPage {
  border-radius: 24px;
  border: #dadada 1px solid;
  box-shadow: inset 0px 0px 2px #ffffff;
  text-align: left;
  width: 600px;
}

.kycInner {
  margin-top: 16px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
}

.logo {
  margin: 24px 24px 24px 24px;
}

h6 {
  margin: 0;
  color: #303239;
  font-weight: normal;
}

h1,
h6 {
  margin: 0;
}

h1 {
  margin-bottom: 24px;
}
