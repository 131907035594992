@use 'src/styles/globals.scss' as style;

.addressBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    margin: 0;
    @include style.mainText;
    @include style.smallSize;
    color: #595d67;
  }
}

.address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  p {
    margin: 0;
    text-align: start;
    width: 256px;
    word-wrap: break-word;
    font-weight: 500;
    color: #303239;
  }
}

.copy {
  margin-top: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  @include style.border(26px);
  @include style.pointer;
}
