@use 'src/styles/globals.scss' as style;

.buttonLink {
  position: relative;
  z-index: 15;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .title {
    max-width: 212px;
    text-align: start;
    margin-left: 12px;
    @include style.mainText;
    @include style.smallSize;
    color: #303239;
  }
  @include style.pointer;
}

.icon {
  // margin-top: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  @include style.border(26px);
  @include style.pointer;
  span {
    margin-left: 60px;
  }
}
