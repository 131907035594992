.containerPage {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.leftBlock {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 156px;
  padding-right: 138px;
}

.listContainer {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 172px;
}

.empty {
  min-height: inherit;
  display: flex;
  align-items: center;
}

.divider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

@media (max-width: 1800px) {
  .listContainer {
    justify-content: center;
    padding-left: 0;
  }
}

@media (max-width: 1700px) {
  .leftBlock {
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: center;
  }
}

@media (max-width: 1350px) {
  .containerPage {
    flex-direction: column;
  }
  .divider {
    display: none;
  }
  .listContainer {
    min-height: 100%;
    margin-bottom: 70px;
  }
  .empty {
    margin-top: 70px;
  }
}

@media (max-width: 520px) {
  .listContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
}
