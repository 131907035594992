@use 'src/styles/globals.scss' as style;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerSide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include style.pointer;
  span {
    margin-left: 8px;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #484b54;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 1px solid #cacedb;
  border-radius: 4px;
}
