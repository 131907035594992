@use 'src/styles/globals.scss' as style;

.checkbox {
  position: relative;
  width: 40px;
  height: 24px;
  background: linear-gradient(180deg, #cacedb 0%, #ffffff 100%);
  border-radius: 20px;
  padding: 1px;
  box-sizing: border-box;

  input {
    z-index: 15;
    margin: 0;
    position: relative;
    width: 38px;
    height: 22px;
    -webkit-appearance: none;
    background: #CACEDB;
    box-shadow: -2px -2px 24px #FFFEFA, inset 2px 2px 3px #B3B6C2;
    outline: none;
    border-radius: 20px;
    box-sizing: border-box;
    &:checked {
      background: #32d583;
      box-shadow: -2px -2px 24px #fffefa, inset 2px 2px 3px #23b36a;
    }
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      background: linear-gradient(
        327.3deg,
        #f8faff 8.95%,
        #fcfcfd 46.23%,
        #f2f5fb 82.96%
      );
      box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.6),
        2px 2px 4px rgba(0, 16, 47, 0.15);
      border-radius: 24px;
      transition: 0.2s;
    }
    &:checked:after {
      left: 19px;
      box-shadow: -2px -2px 4px #66eeaf, 2px 2px 4px rgba(0, 16, 47, 0.15);
    }
  }
}
