@use 'src/styles/globals.scss' as style;

.depositSide {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.depositBlock {
  margin-top: 32px;
  box-sizing: border-box;
  width: 100%;
  height: 212px;
  border-radius: 24px;
  @include style.borderBlock;
  padding: 24px 20px;
  @include style.mainText;
  text-align: start;
  p,
  h3 {
    margin: 0;
  }
}

.info {
  @include style.mainSize;
  p {
    color: #757985;
    font-weight: 500;
  }
  span {
    color: #303239;
  }
}

.total {
  margin-top: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #303239;
  span {
    font-weight: 500;
    @include style.mainSize;
    color: #12b76a;
  }
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include style.smallSize;
  color: #757985;
  span {
    font-weight: 500;
    color: #303239;
  }
}
