@use 'src/styles/globals.scss' as style;

.actionsBlock {
  @include style.myScoreBlock;
  padding: 36px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .actionsBlock {
    width: 100%;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}

@media (max-width: 500px) {
  .actionsBlock {
    padding: 36px;
  }
}
