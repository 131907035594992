@use 'src/styles/globals.scss' as style;

.deals {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  @include style.mainText;
  @include style.smallerSize;
  color: #484b54;
}

.total span {
  margin-left: 4px;
  color: #303239;
  font-weight: 500;
}

.performed {
  margin-left: 50px;
  span {
    margin-left: 4px;
    color: #12b76a;
    font-weight: 500;
  }
}
