@use 'src/styles/globals.scss' as style;

.topUp {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
  h3 {
    margin: 0;
    @include style.mainText;
    @include style.mainText;
    font-weight: 500;
    color: #303239;
  }
}

.infoItem {
  text-align: start;
  margin-top: 12px;
  p {
    margin: 0;
    @include style.mainText;
    @include style.smallSize;
    color: #757985;
  }
  .header {
    padding-bottom: 4px;
    font-weight: 500;
    color: #303239;
  }
}
