@use 'src/styles/globals.scss' as style;

.offerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 16px 20px;
  width: 344px;
  height: 108px;
  background: #f4f6fb;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  @include style.pointer;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.userName {
  @include style.mainText;
  @include style.smallSize;
  font-weight: 500;
  color: #303239;
}

.exchangeRate {
  margin-top: 4px;
  @include style.mainText;
  @include style.smallSize;
  color: #484b54;
}

