@use 'src/styles/globals.scss' as style;

$progress-bar-stroke-width: 1.8;
$progress-bar-size: 72px;

.depositItem {
  width: 436px;
  height: 106px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  p {
    margin: 0;
  }
  span {
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #232428;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }
  @include style.pointer;
}

.rate {
  position: relative;
  width: 72px;
  height: 72px;
  background: #f9f9fa;
  box-shadow: -2px -1px 8px rgba(255, 255, 255, 0.79), -8px -6px 16px #fffefa,
    4px 2px 24px -3px rgba(195, 205, 223, 0.56), inset 2px 4px 4px #ffffff,
    inset -2px -4px 12px -2px #eaedf2;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  margin-left: 24px;
  width: calc(100% - 96px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.month {
  text-align: start;
  margin-top: 8px;
  span {
    padding-bottom: 8px;
  }
}

.progress {
  position: absolute;
  width: 72px;
  height: 72px;
}

svg {
  position: relative;
  height: $progress-bar-size;
  transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progressBar {
  fill: none;
  stroke: #12b76a;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100 - 8;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 1s ease-in-out;
}

@media (max-width: 520px) {
  .depositItem {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
  }
  .info {
    flex-wrap: wrap;
  }
}
