@use 'src/styles/globals.scss' as style;

.notificationsBlock {
  padding-top: 116px;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  position: relative;
}

.line {
  height: 100%;
  position: absolute;
  top: 0;
  left: 842px;
}

.notificationsList {
  width: 842px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 156px;
  padding-right: 96px;
}

.notificationsHeader {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #303239;
  }
}

.chatsBlock {
  position: relative;
  padding-left: 93px;
  width: 436px;
  position: relative;
  z-index: 15;
}

.tabsContainer {
  display: none;
}

@media (max-width: 1580px) {
  .notificationsBlock {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .notificationsList {
    width: 642px;
    padding: 0 50px;
  }
  .chatsBlock {
    padding: 0 50px;
  }
  .line {
    left: calc(50% + 50px);
  }
}

@media (max-width: 1580px) {
  .notificationsList,
  .chatsBlock {
    width: 500px;
    padding: 0 40px;
    box-sizing: content-box;
  }
  .line {
    left: 50%;
  }
}

@media (max-width: 1100px) {
  .notificationsList,
  .chatsBlock {
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .notificationsBlock {
    padding-top: 70px;
  }
}

@media (max-width: 600px) {
  .tabsContainer {
    display: flex;
    position: absolute;
    top: 16px;
    left: calc(50% - 116px);
  }
  .line {
    display: none;
  }
}

@media (max-height: 950px) {
  .notificationsBlock {
    padding-top: 70px;
  }
}
