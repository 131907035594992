@use 'src/styles/globals.scss' as style;

.activeOffers {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @include style.mainText;
  @include style.mainSize;
  font-weight: 500;
  color: #303239;
  text-align: start;
  p {
    margin: 0;
  }
  span {
    @include style.smallSize;
    color: #757985;
  }
}

.offersList {
  margin-top: 16px;
}
