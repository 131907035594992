@use 'src/styles/globals.scss' as style;

.transactions {
  width: 592px;
  height: 432px;
  background: #fafbfd;
  border-radius: 20px;
  overflow: hidden;
}

.header {
  padding: 24px 32px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span {
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #757985;
  }
}

.image:hover {
  cursor: pointer;
}

.transactionsList {
  margin: 20px 4px 10px 32px;
  max-height: 330px;
  box-sizing: border-box;
  @include style.scrollbar(#ECEFF5);
}

.transactionItem {
  margin-bottom: 16px;
  margin-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    text-align: right;
    margin: 0;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #303239;
  }
  .date {
    @include style.mainText;
    @include style.smallSize;
    color: #a1a6b3;
  }
}

.type {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.typeTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
  span {
    @include style.mainText;
    @include style.smallSize;

    color: #232428;
  }
  .currency {
    color: #757985;
  }
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  width: 36px;
  height: 28px;

  background: #e9ebf2;
  border-radius: 46px;
  box-sizing: border-box;
}

@media (max-width: 1300px) {
  .transactions {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    order: 0;
  }
}

@media (max-width: 650px) {
  .transactions {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .header  {
    padding: 16px 16px 0;
  }
  .transactionsList {
    margin: 20px 4px 10px 16px;
  }
  .transactionItem {
    margin-bottom: 8px;
    margin-right: 16px;
  }
}
