@use 'src/styles/globals.scss' as style;

.changerBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.exchangeFund {
  margin-top: 16px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  @include style.mainText;
  @include style.smallSize;
  color: #757985;
}

.fund {
  @include style.mainText;
  @include style.smallSize;
  color: #232428;
  span {
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  .changerBlock {
    width: 100%;
  }
}
