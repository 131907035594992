@use 'src/styles/globals.scss' as style;

.infiniteLine {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 32px;
  bottom: 32px;
  left: 0;
  background: #f04438;
  padding: 4px 0;
  overflow: hidden;
  box-sizing: border-box;
  .text, .text1 {
    transform: translateX(-40px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 600;
    color: #ffffff;
    width: 592px;
    span {
      padding: 0 10px;
    }
  }
  .text {
    animation: marquee-text 5s linear infinite;
  }
  .text1 {
    animation: marquee-text 5s linear infinite;
  }
}

@keyframes marquee-text {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-text1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
