@use 'src/styles/globals.scss' as style;

.depositList {
  margin-top: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.statisticDeposit {
  width: 624px;
}

@media (max-width: 680px) {
  .statisticDeposit {
    width: 100%;
  }
}
