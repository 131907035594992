@use 'src/styles/globals.scss' as style;

.support {
  width: 100%;
  height: 80px;
  background: #eceff5;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include style.pointer;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include style.mainText;
  @include style.smallSize;
  text-align: start;
  p {
    margin: 0;
    color: #232428;
  }
  span {
    margin-top: 4px;
    @include style.smallSize;
    color: #595d67;
  }
}

.icon {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  @include style.border(26px);
  span {
    margin-left: 60px;
  }
}
