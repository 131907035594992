@use 'src/styles/globals.scss' as style;

.accountBlock {
  margin-top: 60px;
  width: 100%;
  height: 80px;

  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(0, 16, 47, 0.15);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  img {
    width: 40px;
    height: 40px;
  }
}

.text {
  text-align: start;
  margin-left: 16px;
  @include style.mainText;
  p {
    margin: 0;
    font-weight: 500;
    @include style.smallerSize;
    color: #a1a6b3;
  }
  .name {
    @include style.mainSize;
    font-weight: 500;
    color: #303239;
  }
}

.details {
  @include style.pointer;
}

.logout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  @include style.border(26px);
  @include style.pointer;
}
