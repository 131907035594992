@use 'src/styles/globals.scss' as style;

.activeDeals {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.header {
  @include style.mainText;
  @include style.mainSize;
  font-weight: 500;
  color: #303239;
}

.deals {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dealItem {
  margin-top: 16px;
  box-sizing: border-box;
  width: 436px;
  height: 188px;
  background: #f4f6fb;
  border: 1px solid #ffffff;
  padding: 24px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
}

@media (max-width: 500px) {
  .dealItem {
    width: 100%;
    height: 100%;
  }
}
