@use 'src/styles/globals.scss' as style;

.borderContainer {
  width: 904px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 23px;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 135px;
  padding-bottom: 65px;

  @include style.borderBlock;
}

.logo {
  position: absolute;
  top: 48px;
  left: 48px;
  width: 90px;
  height: 40px;
}

.headerInvoice {
  @include style.mainText;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #303239;
}

.invoice {
  @include style.sideContainer;
  margin-top: 12px;
  box-sizing: border-box;
  position: relative;
  z-index: 15;

  @include style.mainText;
  @include style.smallSize;
  text-align: start;
  color: #757985;
  p {
    margin: 0;
  }
  span {
    font-weight: 500;
    color: #232428;
  }
}

.user {
  margin-top: 12px;
}

.currency {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 24px;
  @include style.mainSize;
}

.total {
  padding-left: 73px;
}

.network {
  margin-top: 32px;
  margin-bottom: 24px;
  color: #595d67;
}

.information {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
}

.informationIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f9f9fa;
  box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.79), -4px -4px 12px #fffefa,
    4px 2px 14px rgba(195, 205, 223, 0.56), inset 2px 4px 4px #ffffff,
    inset -2px -4px 12px -2px #eaedf2;
  border-radius: 64px;
}

.description {
  margin-left: 16px;
  width: 288px;
  .link {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
