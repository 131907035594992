@use 'src/styles/globals.scss' as style;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 20px 12px 4px;
  isolation: isolate;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06));

  background: #ffffff;
  border-radius: 12px;
  .arrow {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 20;
  }
}

.placeholder {
  position: absolute;
  top: 4px;
  left: 12px;
  @include style.smallerSize;
  font-weight: 500;
  color: #595d67;
  z-index: 15;
}

.select {
  position: relative;
  width: 100%;
  height: 24px;
}

.select select {
  background: #ffffff;
  -webkit-appearance: none;
  width: 100%;

  border: none;
  cursor: pointer;
  @include style.mainText;
  @include style.mainSize;
  color: #232428;
  transition: all 150ms ease;
}

.select select:required:invalid {
  color: #5a667f;
}

.select select option {
  @include style.mainText;
  @include style.mainSize;
  color: #232428;
  &:selected {
    background: #5a667f;
  }
}

.select select option[value=''][disabled] {
  display: none;
}

.select select:focus {
  outline: none;
}

.container:focus-within {
  @include style.border(12px);
}

.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.errorText {
  text-align: start;
  height: 16px;
  @include style.mainText;
  font-size: 12px;
  line-height: 16px;
  color: #f04438;
}
