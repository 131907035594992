@use 'src/styles/globals.scss' as style;

.exchangeList {
  margin-top: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.exchangeBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.notice {
  width: 624px;
}

.settings {
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.worked {
  display: flex;
  align-items: center;
  span {
    margin-right: 42px;
    @include style.mainText;
    @include style.smallSize;
    font-weight: 500;
    font-size: 14px;
    color: #484b54;
  }
}

.workDay {
  width: 312px;
}

@media (max-width: 680px) {
  .statisticDeposit,
  .notice {
    width: 100%;
  }
  .settings {
    flex-direction: column;
    align-items: flex-start;
  }
  .workDay {
    width: 100%;
    margin-top: 16px;
  }
  .exchangeList {
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: center;
  }
}

@media (max-width: 520px) {
  .exchangeList {
    width: 100%;
    padding: 0;
  }
}
