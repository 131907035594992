@use 'src/styles/globals.scss' as style;

.offerSide {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
  align-items: center;
}

.offerBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
  width: 100%;
  @include style.borderBlock;
  position: relative;
  z-index: 15;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 24px;
}

.empty {
  margin-top: 48px;
}

.button {
  margin-top: 72px;
  width: 100%;
}

.open {
  position: relative;
  z-index: 15;
  margin-top: 24px;
  width: 100%;
}

.chatBlock {
  width: 100%;
  margin-top: 48px;
}
