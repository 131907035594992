@use 'src/styles/globals.scss' as style;

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include style.mainText;
  h3,
  p {
    margin: 0;
    text-align: start;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #fafbfd;
  }
  p {
    padding-top: 8px;
    @include style.mainSize;
    color: #a1a6b3;
  }
}
