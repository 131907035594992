@use "src/styles/globals.scss" as style;

.borderContainer {
  width: 904px;
  height: 640px;
  box-sizing: border-box;
  border-radius: 23px;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 119px;

  @include style.borderBlock;
  background: #ECEFF5;
}
