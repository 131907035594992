@use 'src/styles/globals.scss' as style;

.loanOffer {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.container {
  margin-top: 32px;
  @include style.borderBlock;
  border-radius: 24px;
}

.loanOfferBlock {
  padding: 24px 32px;
  text-align: start;
  position: relative;
  z-index: 15;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  @include style.mainText;
  @include style.mainSize;

  h3 {
    margin: 0;
    font-weight: 500;
    color: #303239;
  }
  span {
    @include style.smallSize;
    color: #757985;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 24px;
}

.button {
  width: 162px;
}
