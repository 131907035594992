@use 'src/styles/globals.scss' as style;

.sideBar {
  position: relative;
  width: 196px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
  position: fixed;
  overflow-y: hidden;
  @include style.scrollbar(#ffffff);
}

.sideBarLogo {
  margin-top: 36px;
}

.sideBarMenu {
  margin-top: 112px;
}

.setting, .logout {
  margin-top: 119px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  box-sizing: border-box;
  width: 72px;
  height: 72px;
  border: 1px solid #cacedb;
  backdrop-filter: blur(3px);
  border-radius: 74px;
  @include style.pointer;
  margin-bottom: 30px;
}

.logout {
  margin: 0 0 30px 0;
}

@media (max-height: 950px) {
  .sideBarMenu {
    margin-top: 60px;
  }
  .setting {
    margin-top: 30px;
  }
}

@media (max-width: 960px) {
  .sideBar {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .sideBar {
    z-index: 20;
    background: #eceff5;
    transform: translateX(-100%);
    transition: 0.3s ease-in;
  }
}
