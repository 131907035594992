@use 'src/styles/globals.scss' as style;

.activateAccount {
  padding-bottom: 24px;
}

.error {
  display: block;
  color: #ff0000;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 14px;
}
