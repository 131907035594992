@use 'src/styles/globals.scss' as style;

.header {
  @include style.mainText;
  @include style.mainSize;
  font-weight: 500;
  color: #484b54;
}

.total {
  margin-top: 8px;
  @include style.mainText;
  @include style.mainSize;
  color: #484b54;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #12b76a;
  }
}
