@use 'src/styles/globals.scss' as style;

.investDataTabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  box-sizing: border-box;

  min-width: 113px;
  height: 24px;
  background: #a1a6b3;
  border-radius: 24px;
  p {
    margin: 0;
    @include style.mainSize;
    @include style.smallSize;
    font-weight: 500;
    color: #ffffff;
  }
  img {
    margin-left: 4px;
  }
  @include style.pointer;
}
