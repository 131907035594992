@use 'src/styles/globals.scss' as style;

.investData {
  width: 436px;
  height: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  &__total {
    p {
      margin: 0;
      @include style.mainText;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      color: #303239;
    }
    span {
      @include style.mainSize;
      color: #12b76a;
    }
  }
  &__date {
    margin-top: 6px;
    @include style.mainSize;
    @include style.smallSize;
    color: #a1a6b3;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include style.mainText;
  @include style.mainSize;
  font-weight: 500;
  color: #757985;
  margin-bottom: 4px;
}

.graph {
  background-image: url('../../assets/images/myScore/graph_invest.png');
  background-size: 100%;
  width: 432px;
  height: 152px;
  position: relative;
}

.graphTotal {
  position: absolute;
  top: 11px;
  right: 0;
  @include style.mainSize;
  @include style.mainSize;
  font-weight: 600;
  text-align: right;
  color: #484b54;
}

.notList {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1300px) {
  .investData {
    width: 100%;
    height: 100%;
  }
  .graph {
    width: 525px;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1650px) {
  .graph {
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}
