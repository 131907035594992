@use 'src/styles/globals.scss' as style;

.chat {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.container {
  height: 542px;
  padding-right: 10px;
  @include style.scrollbar(#ffffff);
}

.exchange {
  height: inherit;
}

.messages {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  .item {
    position: relative;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 8px;
    box-sizing: border-box;
    background: #232428;
    border-radius: 0px 4px 4px 4px;
    @include style.mainText;
    margin-left: 8px;
    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: -8px;
      right: auto;
      top: 0px;
      bottom: auto;
      border: 8px solid;
      border-color: #232428 transparent transparent transparent;
    }
    p {
      text-align: start;
      margin: 0;
      @include style.smallerSize;
      color: #ffffff;
    }
    span {
      margin-left: 4px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #cacedb;
    }
  }
  .myMessage {
    background: #e9ebf2;
    align-self: flex-end;
    border-radius: 4px 0px 4px 4px;
    margin-left: 0;
    margin-right: 8px;
    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      right: -8px;
      left: auto;
      top: 0px;
      bottom: auto;
      border: 8px solid;
      border-color: #e9ebf2 transparent transparent transparent;
    }
    span {
      color: #a1a6b3;
    }
    p {
      color: #232428;
    }
  }
}

.input {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sendMessage {
  @include style.pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(253.47deg, #4a4e57 4.7%, #16181c 99%);
  border-radius: 8px;
  outline: none;
  border: none;
}

@media (max-width: 600px) {
  .container {
    height: calc(100vh - 190px);
  }
  .messages {
    min-height: calc(100vh - 190px);
  }
  .exchange {
    // height: inherit;
    min-height: inherit;
  }
}
