.myScore {
  padding-top: 84px;
  padding-left: 156px;
  min-height: 100vh;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 592px repeat(2, 436px);
  grid-template-rows: 296px 432px;
  gap: 32px;
}

@media (max-width: 1900px) {
  .myScore {
    justify-content: center;
    padding: 84px 0;
    grid-template-columns: 592px 436px;
    grid-template-rows: 296px repeat(2, 432px);
  }
}

@media (max-width: 1300px) {
  .myScore {
    justify-content: zcenter;
    grid-template-columns: 592px;
    grid-template-rows: repeat(3, 296px) repeat(2, 432px);
    gap: 16px;
  }
}

@media (max-width: 650px) {
  .myScore {
    justify-content: center;
    grid-template-columns: calc(100% - 40px);
    grid-template-rows: repeat(3, 296px) repeat(2, 432px);
    gap: 16px;
  }
}
