@use 'src/styles/globals.scss' as style;

.empty {
  max-width: 309px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 96px;
    height: 96px;
  }
}

.title {
  margin-top: 16px;
  @include style.mainText;
  @include style.mainSize;
  font-weight: 500;

  text-align: center;

  color: #303239;
}

.description {
  margin-top: 8px;
  @include style.mainText;
  @include style.smallSize;

  text-align: center;
  color: #757985;
}

