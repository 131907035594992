@use 'src/styles/globals.scss' as style;

.dealsChnager {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.deal {
  box-sizing: border-box;
  padding: 20px 20px 12px;
  width: 436px;
  height: 72px;
  background: #f4f6fb;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  @include style.mainText;
  @include style.smallSize;
  @include style.pointer;
}

.name {
  display: flex;
  flex-direction: ro2;
  align-items: center;
  justify-content: space-between;
  span {

    font-weight: 500;
    color: #303239;
  }
}

.total {
  margin-top: 4px;
  text-align: start;
  color: #484B54;
}

@media (max-width: 500px) {
  .deal {
    width: 100%;
  }
}
