@use 'src/styles/globals.scss' as style;

.cardContainer {
  position: relative;
  box-sizing: border-box;
  width: 592px;
  height: 296px;
  background: linear-gradient(207.7deg, #4a4e57 8.19%, #16181c 82.79%);
  border-radius: 24px;
  overflow: hidden;
  padding: 32px;
}

.bgCard {
  position: absolute;
  top: 0;
  right: 0;
}

.total {
  margin-top: 44px;
  p {
    margin: 0;
    text-align: start;
    @include style.mainText;
    font-weight: 600;
    font-size: 24px;
    line-height: 68px;
    color: #ffffff;
  }
  span {
    font-size: 20px;
    line-height: 24px;
    color: #a1a6b3;
  }
}

.numbsList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 28px;
}
.numbItem {
  &:not(:first-child) {
    margin-left: 20px;
  }
  p {
    margin: 0;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 500;
    color: #ffffff;
  }
  span {
    color: #a1a6b3;
  }
}

.credits {
  margin-top: 8px;
  p {
    text-align: start;
    margin: 0;
    @include style.mainText;
    @include style.mainSize;
    color: #a1a6b3;
  }
  span {
    padding: 0 4px;
    color: #ffffff;
  }
}

@media (max-width: 650px) {
  .cardContainer {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .cardContainer {
    width: 100%;
    padding: 16px;
  }
  .cardTab {
    padding-top: 10px;
  }
}
