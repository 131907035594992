@use 'src/styles/globals.scss' as style;

.currency {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 32px;
    height: 32px;
  }
  &:not(:first-child),
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  @include style.pointer;
}

.currencyName {
  padding-left: 12px;
  @include style.mainText;
  @include style.mainSize;
  font-weight: 500;
  color: #232428;
  p {
    margin: 0;
  }
  span {
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #a1a6b3;
  }
}
