@use 'src/styles/globals.scss' as style;

.profileDetails {
  height: 100%;
  @include style.sideContainer;
  @include style.scrollbar(#ffffff);
  padding-right: 10px;
}

.user {
  width: 100%;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @include style.mainText;
  @include style.smallSize;
  &:not(:first-child) {
    margin-top: 24px;
  }
  p {
    margin: 0;
    color: #757985;
  }
  span {
    max-width: 212px;
    text-align: right;
    font-weight: 500;
    color: #303239;
  }
}

.email {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .status {
    color: #12B76A;
  }
}
