@use 'src/styles/globals.scss' as style;

.requestsBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.request {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 16px 20px;
  width: 436px;
  height: 212px;
  background: #f4f6fb;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  &:not(:first-child) {
    margin-top: 8px;
  }
}

.header, .place, .comment {
  text-align: start;
  @include style.mainText;
  @include style.smallSize;
  color: #484B54;
  span {
    color: #232428;
  }
}

.place {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.comment {
  margin-top: 12px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  .button {
    width: 192px;
  }
}

@media (max-width: 500px) {
  .request {
    width: 100%;
    height: 100%;
  }
  .buttons {
    flex-direction: column;
    .button {
      width: 100%;
      &:last-child {
        margin-top: 8px;
      }
    }
  }
}
