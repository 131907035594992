@use 'src/styles/globals.scss' as style;

.accountButton {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 80px;
  width: 332px;
  border-radius: 12px;
  padding: 20px 20px 16px;
  box-sizing: border-box;

  background: linear-gradient(253.47deg, #4a4e57 4.7%, #16181c 99%);

  &__text {
    @include style.mainText;
    text-align: start;
    p {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fafbfd;
    }
    span {
      font-size: 12px;
      line-height: 16px;
      color: #a1a6b3;
    }
  }
  @include style.pointer;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background: #fafbfd;
  border-radius: 12px;
  img {
    width: 11px;
    height: 11px;
  }
}
