@use 'src/styles/globals.scss' as style;

.investBlock {
  margin-top: 32px;
  box-sizing: border-box;
  width: 100%;
  height: 172px;
  border-radius: 24px;
  @include style.borderBlock;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include style.mainText;
  span {
    @include style.mainSize;
    font-weight: 500;
    color: #757985;
  }
  .profit {
    @include style.smallSize
  }
}

.total {
  margin-top: 4px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #303239;
}

.profitTotal {
  margin-top: 4px;
  p {
    margin: 0;
    @include style.smallSize;
    font-weight: 600;
    color: #303239;
  }
}
