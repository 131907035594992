@use 'src/styles/globals.scss' as style;

.investDiagram {
  margin-top: 32px;
  width: 904px;
  height: 532px;
  background: linear-gradient(
      327.3deg,
      #f8faff 8.95%,
      #fcfcfd 46.23%,
      #f2f5fb 82.96%
    ),
    #fafbfd;
  border-radius: 20px;
  padding: 40px 32px 28px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datePeriod {
  @include style.mainSize;
  @include style.smallSize;
  font-weight: 500;

  color: #757985;
}

.graph {
  margin-top: 108px;
  background-image: url('../../assets/images/myScore/invest_diagram.png');
  background-size: contain;
  width: 840px;
  height: 300px;
  position: relative;
}

.graphTotal {
  position: absolute;
  top: 104px;
  right: 0;
  @include style.mainSize;
  @include style.mainSize;
  font-weight: 600;
  text-align: right;
  color: #484b54;
}

.axisDate {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    @include style.mainSize;
    @include style.smallSize;
    font-weight: 500;
    color: #757985;
  }
}

@media (max-width: 1900px) {
  .investDiagram {
    width: 592px;
    height: 300px;
    padding: 28px;
  }
  .graph {
    margin-top: 50px;
    width: 100%;
    height: 150px;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .graphTotal {
    top: 50px;
  }
  .axisDate {
    margin-top: 0;
  }
}

@media (max-width: 620px) {
  .investDiagram {
    width: calc(100% - 40px);
    height: 100%;
  }
}

@media (max-width: 450px) {
  .investDiagram {
    padding: 16px;
  }
}
