.qrCoreBlock {
  width: 100%;
  min-height: 232px;

  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(0, 16, 47, 0.15);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 172px;
    height: 172px;
  }
}
