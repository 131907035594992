@use 'src/styles/globals.scss' as style;

.advantages {
  // @include style.flex(column, flex-start, center);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 40px;
  z-index: -1;
  .item {
    span {
      margin-left: 8px;
    }
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @include style.mainText;
    @include style.mainSize;
    color: style.$blackGray;
  }
}

.buttons {
  @include style.buttonsLogin;
}
