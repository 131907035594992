@mixin sideBlock {
  position: relative;
  min-height: 100vh;
  width: 662px;
  background: #eceff5;
  padding: 0;
  padding-left: 156px;
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.27);
  backdrop-filter: blur(16px);
  z-index: 50;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  animation: overlay 0.3s ease-in;
}

.blur {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eceff5;
  opacity: 0.6;
  z-index: 50;
}

.side {
  display: flex;
  flex-direction: row;
}

.sideBlock {
  @include sideBlock;
  animation: sideBlock 0.3s ease-in;
}

.sideBlockSub {
  // animation: sideBlock 0.3s ease-in;
  @include sideBlock;
  padding-top: 24px;
}

@keyframes sideBlock {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes sideBlockSub {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes overlay {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(16px);
  }
}

@media (max-width: 1350px) {
  .sideBlock,
  .sideBlockSub {
    padding-top: 32px;
    padding-left: 0;
    width: 490px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1100px) {
  .sideBlock,
  .sideBlockSub {
    width: calc(100vw / 2);
  }
}

@media (max-width: 780px) {
  .sideBlock,
  .sideBlockSub {
    width: 100vw;
  }
}

@media (max-height: 550px) {
  .sideBlock,
  .sideBlockSub {
    padding: 0px 0;
  }
}
